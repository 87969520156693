.sidebar__wrap {
  height: calc(113.9vh - 100px);
  width: 280px;
  background-color: white;
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

img.logo {
  width: 115px;
  height: 3vh;
  margin: 18px;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 4.5em;
}
a {
  text-decoration: none !important;
}
.menu__single {
  color: black;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}
.menu__single:hover {
  /*  background-color: #f3eaea; */
  text-decoration: transparent;
}
.menu__single active {
  color: white;
}
svg.menu__item {
  width: 28px;
  height: 28px;
  margin-right: 30px;
  color: #424242;
}

.selected__menu {
  /*  background-color: #f3eaea; */
  color: black;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

button.btn {
  width: 180px;
  height: 40px;
  border: 2px white solid;
  margin-bottom: 20px;
}

button.login__btn {
  color: white;
  background-color: transparent;
}
button.signup__btn {
  color: black;
  background-color: white;
}

.bottom-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#nav__brand {
  font-size: x-large;
  font-weight: 600;
  color: black;
}

.MuiIconButton-root {
  padding: 0;
  /*   padding-right: 33px !important;
 */
  background-color: transparent !important;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
}

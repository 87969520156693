.card-expand {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.home__wrap {
  width: calc(111.9vw - 230px - 200px);
  overflow-x: hidden;
  scroll-behavior: unset;
  position: absolute;
  top: 200px;
  left: 240px;
  padding: 40px;
}
.home__wrap::-webkit-scrollbar {
  display: none;
}
hr {
  margin-top: 6px;
}
.actions__cartBtn:hover {
  background-color: #f1f1f1;
  color: black;
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
.actions__cartBtn {
  background-color: black;
  color: white;
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
.actions__heartBtn{
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 52px;
  height: 35px;

}
.actions__heartBtn:hover {
  background-color: #f1f1f1;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 52px;
  height: 35px;

}
.actions__heartBtn:hover .heart-outline{
color: red !important;

}
.actions__heartBtn .heart-outline{
  color: white !important;
  
  }